<!-- 这是打车H5的一个DEMO架子 已配置了移动端单位适配 会自动把px转为rem 写样式的时候用px就好
    地图组件使用的是高德的JSAPI
    只写了一点点😅
 -->
<template>
  <div class="home">
    <div class="title"
         style="display:flex">人才出行</div>
    <div id="container"></div>
    <div class="box"
         @click="core">
      <div style="width:30%;height:70%;display:flex">
        <img style="width:1.8rem;height:1.8rem;align-items:center;margin-left:20px;border-radius:50% "
             :src="this.headimg"
             alt />
      </div>
      <div class="boxtitle imgsbox">
        <div class="title">
          {{this.name}}
          <van-icon style="margin-left:10px"
                    name="edit" />
        </div>
        <div style="font-size:16px;">
          <span style="color:#f00;">￥0.00</span>
          <span style="font-size:20px;margin-left:10px;color:#aaa">|</span>
          <span style="margin-left:10px;">{{this.integral}}积分</span>
        </div>
      </div>
      <!-- <div class="imgsbox">       
       <img src="../assets/images/dll.png" alt="">
      </div>-->
    </div>
    <div class="list">
      <div class="display"
           style="margin-top:20px">
        <div class="leftbox"
             @click="car(0)">
          <img class="img"
               src="../assets/images/dcar.png"
               alt />
          <div class="left">打车</div>
        </div>
        <div class="leftbox"
             @click="car(1)">
          <img src="../assets/images/yy.png"
               alt />
          <div class="left">预约</div>
        </div>
        <div class="leftbox"
             @click="car2(2)">
          <img src="../assets/images/jj.png"
               alt />
          <div class="left">接送机</div>
        </div>
        <div class="leftbox"
             @click="order">
          <img src="../assets/images/dind.png"
               alt />
          <div class="left">订单</div>
        </div>
      </div>
      <div class="display"
           style="margin-top:20px">
        <div class="leftbox"
             @click="fp">
          <img class="img"
               src="../assets/images/fap.png"
               alt />
          <div class="left">发票</div>
        </div>
        <div class="leftbox"
             @click="Rech">
          <img src="../assets/images/chz.png"
               alt />
          <div class="left">充值</div>
        </div>
        <div class="leftbox"
             @click="discount">
          <img src="../assets/images/yh.png"
               alt />
          <div class="left">卡券</div>
        </div>
        <div class="leftbox"
             @click="card">
          <img src="../assets/images/kj.png"
               alt />
          <div class="left">会员</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <div style="margin-top:20px">
        <!-- <div style="text-align: center;color:#AAAAAA">成都米花棠科技有限公司©2023</div> -->
        <div style="text-align: center;color:#AAAAAA;margin-top:30px;margin-bootom">客服热线：400-011-8778</div>
      </div>
    </footer>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import { load_api } from '../http/api/price'
import { newinfo_api, codeopen_api } from '../http/api/certification'
import { Toast } from 'vant'
export default {
  name: 'Home',
  components: {},
  data () {
    return {
      map: null,
      polylineL: null,
      infoWindow: null,
      mapContent: '',
      zoom: '',
      markers: [],
      cluster: null,
      active: 0,
      name: '',
      integral: '',
      headimg: '',
      token: '',
      code: '',
      openid: null,
    }
  },
  created () {
    var img = JSON.parse(sessionStorage.getItem('token'))
    this.headimg = img.headImg
    this.newinfo()
    this.code = localStorage.getItem('code')
    //     alert(this.code);
    //  Toast(this.code)
  },
  mounted () {
    this.load()
    if (!localStorage.getItem('one')) {
      this.codeopid()
    }
    // this.newinfo()
    // this.map = new AMap.Map('container', {
    //   resizeEnable: true, //是否监控地图容器尺寸变化
    //   zoom: 15, //初始化地图层级
    //   center: [113.65, 34.76], //初始化地图中心点
    // })
    // this.zoom = this.map.getZoom() //获取当前地图级别
    // this.map.setMapStyle('amap://styles/whitesmoke') //自定义地图的样式
    sessionStorage.removeItem('endAddress')
    sessionStorage.removeItem('endIp')
    sessionStorage.removeItem('nowOrder')
    sessionStorage.removeItem('startAddress')
    sessionStorage.removeItem('startIp')
    sessionStorage.removeItem('departureTime')
    this.initMap()
  },
  methods: {
    async codeopid () {
      let res = await codeopen_api({
        code: this.code,
      })
      console.log(res)
      if (res.code == 200) {
        res.data.openid != null
          ? localStorage.setItem('openid', res.data.openid)
          : localStorage.setItem('openid', '')
        localStorage.setItem('one', true)
      }
    },
    //最新用户信息
    async newinfo () {
      let res = await newinfo_api({})
      if (res.code == 200) {
        // console.log(res.data.headImg)
        var img = JSON.parse(sessionStorage.getItem('token'))
        img.headImg = res.data.headImg
        console.log(img.headImg)
        img.userName = res.data.userName
        img.integral = res.data.integral
        // sessionStorage.setItem('token', JSON.stringify(img))
        this.integral = res.data.integral
        this.name = res.data.userName
        if (res.data.headImg == null) {
          this.headimg = 'https://img.yzcdn.cn/vant/cat.jpeg'
        } else {
          this.headimg = res.data.headImg
        }
      }
    },
    //判断用户有无未支付订单
    async load () {
      this.token = JSON.parse(sessionStorage.getItem('token')).token
      let res = await load_api({
        token: this.token,
      })
      // console.log('11111111111111111111',res);
      if (res.code == 200) {
        if (res.data != null) {
          sessionStorage.setItem('nowOrder', JSON.stringify(res.data))
          sessionStorage.setItem('startAddress', res.data.startAddress)
          sessionStorage.setItem('startIp', [
            res.data.fromLongitude,
            res.data.fromLatitude,
          ])
          sessionStorage.setItem('endAddress', res.data.endAddress)
          sessionStorage.setItem('endIp', [
            res.data.toLongitude,
            res.data.toLatitude,
          ])
          Dialog.confirm({
            title: '提示',
            message: '你有笔订单未完成',
            confirmButtonColor: '#09BB07',
          })
            .then(() => {
              this.$router.push({
                path: '/car',
              })
            })
            .catch(() => {
              // on cancel
            })
        }
      }
    },
    //个人中心
    core () {
      this.$router.push({
        path: '/core',
      })
    },
    //打车
    car (e) {
      this.$router.push({ path: '/home', query: { active: e } })
    },
    //接送机
    car2 (e) {
      this.$router.push({ path: '/home2', query: { active: e } })
    },
    //充值
    Rech () {
      //Toast('暂未开放')
      this.$router.push({
        path: '/Rech',
      })
    },
    //卡券
    card () {
      Toast('暂未开放')
      // this.$router.push({
      //     path: '/card',
      // })
    },
    //优惠
    discount () {
      this.$router.push({
        path: '/discount',
      })
    },
    //发票
    fp () {
      // Toast('暂未开放')
      this.$router.push({
        path: '/Invoice',
      })
    },
    //优惠
    order () {
      this.$router.push({
        path: '/order',
      })
    },
    initMap () {
      var mapObj = new AMap.Map('container') //以为这里是要获取地图容器的id,所以要写在mounted之中
      var geolocation
      var that = this
      mapObj.plugin('AMap.Geolocation', function () {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: false, // 显示定位按钮，默认：true
          buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        })
        mapObj.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', that.onComplete) // 返回定位信息
        AMap.event.addListener(geolocation, 'error', that.onError) // 返回定位出错信息
      })
    },
    // onComplete(obj) {
    //   this.positionInfo = obj
    //   var res =
    //     '经纬度：' +
    //     obj.position +
    //     '\n精度范围：' +
    //     obj.accuracy +
    //     '米\n定位结果的来源：' +
    //     obj.location_type +
    //     '\n状态信息：' +
    //     obj.info +
    //     '\n地址：' +
    //     obj.formattedAddress +
    //     '\n地址信息：' +
    //     JSON.stringify(obj.addressComponent, null, 4)
    //   console.log(this.positionInfo)
    //   console.log(res)
    // },
    // onError(obj) {
    //   alert(obj.info + '--' + obj.message)
    //   console.log(obj)
    // },
  },
}
</script>
<style scoped lang="scss">
.home {
  // padding-bottom: 1rem;
  .title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .box {
    z-index: 9;
    position: relative;
    margin-top: 33vh;
    // top:33vh;
    display: flex;
    background-color: #fff;
    height: 96px;
    width: 95%;
    border-radius: 30px;
    margin-left: 2.5%;
    align-items: center;
    box-shadow: #ccc 0 0 10px;
    .imgsbox {
      background: url("../assets/images/dll.png") no-repeat;
      height: 96px;
      background-position: right top;
    }
    .boxtitle {
      margin-left: 10px;
      width: 70%;
      // flex: 1;
      .title {
        margin-top: 10px;
        font-size: 16px;
        margin-right: 40%;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .list {
    height: 40vh;
    .display {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 30%;
      .leftbox {
        text-align: center;
        color: #666666;
        font-size: 14px;
      }
    }
  }
}

#container {
  width: 100%;
  height: 40vh;
  position: absolute;
}

.infoBox {
  position: fixed;
  bottom: 20px;
  width: 100%;
  .tabs {
    width: 90%;
    margin: auto;
    margin-bottom: 15px;
  }
  .locationInfo {
    width: 90%;
    height: 120px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
  }
}

.footer {
  background: url("../assets/images/foot.png") no-repeat;
  background-size: 100% 100%;
  height: 13%;
  width: 100%;
  // background-color: #eee;
  //border-radius: 60% 60% 0 0;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
</style>
